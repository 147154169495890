<template>
  <section class="container py-4">
    <router-link :to="{ name: 'applications' }" class="mb-3 d-inline-flex text-decoration-none">
      <span class="material-icons-outlined">chevron_left</span> All applications
    </router-link>
    <div v-if="loading || app === null"><LoaderPic />Loading...</div>
    <div v-else-if="app">
      <h5 class="mb-5">
        <span class="mr-2">Statistics for application {{ app.kid }} ({{ app.environment }})</span>
      </h5>
    </div>
    <div v-if="loadingASPSPs" class="text-center">
      <LoaderPic />Loading...
    </div>
    <div v-else>
      <statistics-filter class="mb-2" @submit="applyFilter" @reset="applyFilter" :countries="countries"/>
      <aspsp-statistics-list :AIS="hasAIS" :PIS="hasPIS" :statistics="aspspStatistics" />
      <div v-if="fetchingAspspStatistics" class="text-center">
        <loader-pic />
      </div>
      <div class="text-center" v-else-if="!aspspStatistics.length">
        No results
      </div>
      <endpoint-statistics-list :statistics="endpointStatistics" />
      <div v-if="fetchingEndpointStats" class="text-center">
        <loader-pic />
      </div>
      <div class="text-center" v-else-if="!endpointStatistics.length">
        No results
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'
import StatisticsFilter from '../components/applications/StatisticsFilter.vue'
import AspspStatisticsList from '../components/applications/AspspStatisticsList.vue'
import EndpointStatisticsList from '../components/applications/EndpointStatisticsList.vue'
import axios from 'axios'

const now = new Date()
const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)

export default {
  name: 'ApplicationStatistics',
  data() {
    return {
      app: null,
      services: [],
      hasAIS: false,
      hasPIS: false,
      appId: '',
      aspspStatistics: [],
      endpointStatistics: [],
      fetchingEndpointStats: false,
      fetchingAspspStatistics: false,
      fetchOptions: {},
      dateFrom: startOfMonth.toISOString(),
      dateTo: endOfMonth.toISOString(),
      loadingASPSPs: false,
      countries: null
    }
  },
  created() {
    this.getASPSPs()
  },
  components: {
    LoaderPic,
    StatisticsFilter,
    AspspStatisticsList,
    EndpointStatisticsList
  },
  computed: {
    ...mapGetters(['applications', 'loading', 'user', 'idToken'])
  },
  async mounted() {
    if (!this.applications.length) {
      await this.getApplications()
    }
    try {
      const appId = this.$route.params.id
      this.app = this.applications.find((app) => app.kid === appId)
    } catch (error) {
      console.error('Error finding app:', error)
    }
    this.services = this.app.services
    this.hasAIS = this.services.includes('AIS')
    this.hasPIS = this.services.includes('PIS')
    if (!this.app) {
      this.$router.push({ path: '/cp/applications' })
    }
    this.getASPSPs()
    this.fetchAspspStats()
    this.fetchEndpointStats()
  },
  methods: {
    ...mapActions(['getApplications', 'fetchStatistics']),
    async fetchAspspStats() {
      const params = {
        appId: this.app.kid,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      }
      Object.assign(params, this.fetchOptions)
      this.fetchingAspspStatistics = true
      try {
        this.aspspGroupedStatistics = []
        const data = await this.fetchStatistics(params)
        const aspspGroupedStatistics = data.aspsp_grouped_statistics
        this.aspspStatistics.push(...aspspGroupedStatistics)
      } finally {
        this.fetchingAspspStatistics = false
      }
    },
    async fetchEndpointStats() {
      const params = {
        appId: this.app.kid,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      }
      Object.assign(params, this.fetchOptions)
      this.fetchingEndpointStats = true
      try {
        this.endpointStatistics = []
        const data = await this.fetchStatistics(params)
        const endpointStatistics = data.endpoint_grouped_statistics
        this.endpointStatistics.push(...endpointStatistics)
      } finally {
        this.fetchingEndpointStats = false
      }
    },
    applyFilter(e) {
      this.fetchOptions = {}
      for (const filter of ['brand', 'country', 'psuType', 'dateFrom', 'dateTo']) {
        if (e[filter]) {
          this.fetchOptions[filter] = e[filter]
        }
      }
      this.aspspStatistics = []
      this.fetchAspspStats()
      this.endpointStatistics = []
      this.fetchEndpointStats()
    },
    async getASPSPs() {
      this.loadingASPSPs = true
      try {
        const aspspsResponse = await axios.get(
          '/api/aspsps', {
            headers: {
              Authorization: `Bearer ${this.idToken.token}`
            }
          }
        )
        const countries = {}
        for (const aspsp of aspspsResponse.data.aspsps) {
          const countryASPSPs = countries[aspsp.country] || []
          aspsp.psu_types = aspsp.psu_types.sort()
          countryASPSPs.push(aspsp)
          countries[aspsp.country] = countryASPSPs.sort((a, b) => a.name.localeCompare(b.name))
        }
        this.countries = countries
      } catch (e) {
        this.pushAlertMessage({
          message: 'Unable to get ASPSPs'
        })
      } finally {
        this.loadingASPSPs = false
      }
    }
  }
}
</script>
