<template>
  <section class="container py-5">
    <div v-if="loading">Loading...</div>
    <div v-else>
      <p>
        <strong>E-mail:</strong>
        {{ user.email }}
        <span class="text-success" v-if="user.emailVerified">(verified)</span>
        <span v-else>(not verified)</span>
      </p>
      <p>
        <strong>SMS Multi-factor Authentication: </strong>
        {{ getPhoneNumber() }}
        <span class="text-success" v-if="isSMSFactorEnabled">(enabled)</span>
        <button class="btn btn-primary btn-sm ml-2" v-if="!isSMSFactorEnabled && !showSMSEnrollment" @click="toggleSMSEnrollment">Enable</button>
      </p>
      <p v-if="user.displayName">
        <strong>Name:</strong>
        {{ user.displayName }}
      </p>
      <div class="row">
        <SMSEnrollment class="col-md-6" v-if="showSMSEnrollment"></SMSEnrollment>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import metadata from '../mixins/metadata.js';
import SMSEnrollment from '../components/SMSEnrollment.vue';

export default {
  name: 'UserProfile',
  mixins: [metadata],
  data() {
    return {
      showSMSEnrollment: false
    };
  },
  computed: {
    ...mapGetters(['user', 'loading']),
    isSMSFactorEnabled() {
      const { enrolledFactors } = this.user.multiFactor;
      return enrolledFactors && enrolledFactors.some(factor => factor.factorId === "phone");
    },
  },
  components: {
    SMSEnrollment
  },
  methods: {
    toggleSMSEnrollment() {
      this.showSMSEnrollment = !this.showSMSEnrollment;
    },
    getPhoneNumber() {
      const { enrolledFactors } = this.user.multiFactor;
      const phoneFactor = enrolledFactors.find(factor => factor.factorId === 'phone');
      return phoneFactor ? phoneFactor.phoneNumber : '';
    }
  }
}
</script>
