<template>
  <div>
    <div class="input-group mb-3">
      <input type="text" class="form-control" :disabled="phoneNumberVerified" v-model="phoneNumber" placeholder="Phone number, e.g. +358414123456">
      <div class="input-group-append">
        <button class="btn btn-primary" :disabled="loading || !isValidPhoneNumber || phoneNumberVerified" @click="SMSEnrollment">Send Code</button>
      </div>
    </div>
    <div v-if="phoneNumberVerified">
      <div class="input-group">
      <input type="text" autocomplete="off" class="form-control" v-model="verificationCode" placeholder="Enter verification code">
      <div class="input-group-append">
        <button class="btn btn-primary" :disabled="loading" @click="verifyCode">Verify Code</button>
      </div>
    </div>
    <p class="mt-1 text-center small">After code verification, Multi-factor Authentication will be enabled, and you will be forced to authenticate again</p>
    </div>
    <div id="recaptcha-container"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import firebase from 'firebase/app';

export default {
  data() {
    return {
      recaptchaVerifier: null,
      phoneNumber: '',
      verificationCode: '',
      phoneNumberVerified: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    isValidPhoneNumber() {
      return /^(\+)?([0-9\s\-\(\)]{10,20})$/.test(this.phoneNumber);
    }
  },
  mounted() {
    this.initializeRecaptcha();
  },
  methods: {
    ...mapActions(['logout', 'pushAlertMessage']),
    initializeRecaptcha() {
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: () => { this.sendVerificationCode() },
        'expired-callback': this.onRecaptchaExpired,
      });
    },
    async SMSEnrollment() {     // localhost is not accepted by the firebase, so local ip instead
      this.loading = true;
      try {
        await this.recaptchaVerifier.verify();
        console.log("reCAPTCHA verified successfully.");
      } catch (error) {
        this.pushAlertMessage({
          message: `Failed to verify reCAPTCHA`
        })
        console.error(error)
      } finally {
        this.loading = false;
      }
    },
    async sendVerificationCode() {
      this.loading = true;
      try {
        const multiFactorSession = await this.user.multiFactor.getSession();
        const phoneInfoOptions = {
          phoneNumber: this.phoneNumber,
          session: multiFactorSession
        };
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        this.verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier);
        this.phoneNumberVerified = true;
        this.pushAlertMessage({
          message: `Verification code sent to: ${this.phoneNumber}`, type: 'info'
        })
      } catch (error) {
        this.pushAlertMessage({
          message: error.message
        })
        console.error(error)
      } finally {
        this.loading = false;
      }
    },
    async verifyCode() {
      this.loading = true;
      try {
        if (!this.verificationId) {
          console.error("No valid verification id available.");
          return;
        }
        const credential = firebase.auth.PhoneAuthProvider.credential(this.verificationId, this.verificationCode);
        const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(credential);
        await this.user.multiFactor.enroll(multiFactorAssertion, "Phone number");

        this.pushAlertMessage({
          message: `Phone number ${this.phoneNumber} enrolled successfully for SMS MFA! Please re-login`, type: 'info'
        })
        setTimeout(() => {
          this.logout()
          this.$router.push({ name: 'sign-in' })
        }, 3000)
      } catch (error) {
        this.pushAlertMessage({
          message: `Failed to register SMS MFA`
        })
        console.error(error)
      } finally {
        this.loading = false;
      }
    },
    onRecaptchaExpired() {
      this.recaptchaVerifier.reset();
    }
  }
}
</script>
