<template>
  <section class="container py-5">
    <div v-if="loading">Loading...</div>
    <div v-else>
      <p>
        <strong>E-mail:</strong>
        {{ user.email }}
        <span class="text-success" v-if="user.emailVerified">(verified)</span>
        <span v-else>(not verified)</span>
      </p>
      <p v-if="user.displayName">
        <strong>Name:</strong>
        {{ user.displayName }}
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import metadata from '../mixins/metadata.js'

export default {
  name: 'UserProfile',
  mixins: [metadata],
  computed: {
    ...mapGetters(['user', 'loading'])
  }
}
</script>
