<template>
  <div v-if="info && !isEditing">
    <div class="mb-5">
      <h4>
        <span v-if="info.shortname">{{info.shortname}} ●</span>
        {{info.name}}
        <img
          :src="require(`../../../assets/countries/${info.country}.svg`)"
          alt
          class="bank-card__flag mr-2 mb-1"
        />
        <a :href="info.homepage" target="_blank" v-if="info.homepage">
          <span class="external-link"></span>
        </a>
      </h4>
      <div class="mb-4">
        <span class="mr-4">Legal name: {{info.legal_name}}</span>
        <span class="mr-4" v-if="info.bic">BIC: {{info.bic}}</span>
        <span class="mr-4" v-if="info.objectID">ECB MFI code: {{info.objectID}}</span>
      </div>
      <h5>Integration status</h5>
      <span v-if="!info.connectors && info.wip && info.wip.connectors" class="d-block">
        {{ integrationStatuses[info.wip.connectors] }}
      </span>
      <span v-else-if="!info.connectors" class="d-block text-muted">Not integrated</span>
      <span class="d-block" v-if="info.scopes && info.scopes.length">
        APIs:
        <span
          class="text-uppercase btn btn-link btn-sm pt-0 pb-0 border-0 mt-0 mb-0"
          v-for="(scope, index) in info.scopes"
          :key="`details-${index}`"
        >{{scope}}</span>
      </span>
      <span class="d-block" v-if="info.environments && info.environments.length">
        Environments:
        <span
          class="text-capitalize btn btn-link btn-sm pt-0 pb-0 border-0 mt-0 mb-0"
          v-for="(environment, index) in info.environments"
          :key="`details-${index}`"
        >{{environment}}</span>
      </span>
      <span class="d-block" v-if="info.connectors">Connectors:</span>
      <ul v-if="info.connectors">
        <li v-for="(connector, connectorName, index) in info.connectors" :key="`details-${index}`">
          <span class="font-weight-bold align-middle mr-2">{{connectorName}}</span>
          <a
            :href="`/core/#connector-${connectorName}`"
            target="_blank"
            class="text-capitalize btn btn-link btn-sm pt-0 pb-0 border-0 mt-0 mb-0"
          >
            Download&nbsp;
            <span class="external-link"></span>
          </a>
          <a
            :href="`/docs/core/latest/#${connectorName.toLowerCase()}connectorsettings-type`"
            target="_blank"
            class="text-capitalize btn btn-link btn-sm pt-0 pb-0 border-0 mt-0 mb-0"
          >
            Documentation&nbsp;
            <span class="external-link"></span>
          </a>
        </li>
      </ul>
      <h5 class="mt-4">Open banking details</h5>
      <span class="d-block" v-if="info.open_banking.homepage">
        PSD2 home page:
        <a
          class="btn btn-link btn-sm pt-0 pb-0 border-0 mt-0 mb-0"
          :href="info.open_banking.homepage" target="_blank"
        >
          {{info.open_banking.homepage}}
          <span class="external-link"></span>
        </a>
      </span>
      <span class="d-block" v-if="info.open_banking.dev_portal">
        Developer portal:
        <a
          class="btn btn-link btn-sm pt-0 pb-0 border-0 mt-0 mb-0"
          :href="info.open_banking.dev_portal" target="_blank"
        >
          {{info.open_banking.dev_portal}}
          <span class="external-link"></span>
        </a>
      </span>
      <span class="d-block" v-if="info.open_banking.status && info.open_banking.status.sandbox">
        Sandbox status:
        <span
          class="text-capitalize btn btn-link btn-sm pt-0 pb-0 border-0 mt-0 mb-0"
          :class="statusColors[info.open_banking.status.sandbox]"
        >{{info.open_banking.status.sandbox}}</span>
      </span>
      <span class="d-block" v-if="info.open_banking.status && info.open_banking.status.production">
        Production status:
        <span
          class="text-capitalize btn btn-link btn-sm pt-0 pb-0 border-0 mt-0 mb-0"
          :class="statusColors[info.open_banking.status.production]"
        >{{info.open_banking.status.production}}</span>
      </span>
      <span class="d-block" v-if="info.open_banking.api">
        Available APIs:
        <span
          class="text-capitalize btn btn-link btn-sm pt-0 pb-0 border-0 mt-0 mb-0"
          v-for="(details, api, index) in info.open_banking.api"
          :key="`details-${index}`"
        >{{apis[api]}}</span>
      </span>
      <span class="d-block" v-if="info.open_banking.psu_types">
        Supported usage:
        <span
          class="text-capitalize btn btn-link btn-sm pt-0 pb-0 border-0 mt-0 mb-0"
          v-for="psuType in info.open_banking.psu_types"
          :key="`psu-type-${psuType}`"
        >{{psuType}}</span>
      </span>
    </div>

    <!--
    <div v-if="info.updates && info.updates.search" class="mb-3">
      <span class="d-block" v-on:click.prevent="isShowInfo = !isShowInfo" v-if="info.updates && info.updates.modificationDate">
        Last update was {{new Date(info.updates.modificationDate).toLocaleDateString()}}
      </span>
      <h6 class="mt-4"><b>{{info.updates.search.title}}</b></h6>
      <p>
        {{info.updates.search.snippet}}
        <a
          :href="info.updates.search.link"
          v-if="info.updates && info.updates.search && info.updates.search.link"
          target="_blank"
          class="btn btn-link btn-sm"
        >Read more <span class="external-link"></span></a>
      </p>
    </div>
    -->

    <div class="d-flex justify-content-between flex-wrap">
      <div>
        <button v-on:click="$emit('close')" class="btn btn-primary mr-3">Close</button>
      </div>
      <button class="btn btn-link" v-on:click="isEditing = true">Suggest changes</button>
    </div>
  </div>
  <BanksListItemEdit :info="info" v-on:close="$emit('close')" v-else />
</template>

<script>
import { mapState } from 'vuex'
import BanksListItemEdit from './BanksListItemEdit'

export default {
  name: 'BanksListItemBack',
  data() {
    return {
      bankUpdates: {},
      isEditing: false,
      statusColors: {
        unknown: 'text-muted',
        absent: 'text-muted',
        development: 'text-primary',
        restricted: 'text-info',
        available: 'text-success'
      },
      apis: {
        ais: 'account information',
        pis: 'payment initiation',
        cof: 'confirmation of funds'
      }
    }
  },
  components: {
    BanksListItemEdit
  },
  computed: {
    ...mapState({
      countriesList: state => state.countriesList,
      integrationStatuses: (state) => state.integrationStatuses
    })
  },
  mounted() {
    document.title = `${this.info.shortname || this.info.name} Open Banking APIs availability and integration status - Enable Banking`
    let description = `Information about ${this.info.shortname || this.info.name} open banking APIs provided in ${this.countriesList[this.info.country]} is not available.`
    if (this.info.open_banking) {
      description = `Information about ${this.info.shortname || this.info.name} open banking APIs in ${this.countriesList[this.info.country]}.`
      const apis = this.info.open_banking.api ? Object.keys(this.info.open_banking.api) : []
      if (apis.length) {
        description += ` PSD2 APIs provided: ${apis.map(api => this.apis[api] || api).join(', ')}.`
      }
      if (this.info.open_banking.status) {
        Object.entries(this.info.open_banking.status).forEach(e => {
          description += ` ${e[0].charAt(0).toUpperCase()}${e[0].slice(1)} API - ${e[1]}.`
        })
      }
    }
    if (this.info.scopes && this.info.scopes.length) {
      description += ` Aggregation core integrations: ${this.info.scopes.join(', ')}.`
    }
    if (this.info.environments && this.info.environments.length) {
      description += ` Environments integrated: ${this.info.environments.join(', ')}.`
    }
    document.head.querySelector('meta[name=description]').content = description
  },
  destroyed() {
    this.isEditing = false
    document.title = this.$route.meta.title
    document.head.querySelector('meta[name=description]').content = this.$route.meta.description
  },
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
