<template>
  <form class="mb-5" @submit.prevent="editApp">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Application name"
            v-model="name"
            required
          />
        </div>
        <div class="form-group">
          <textarea
            type="text"
            class="form-control"
            rows="3"
            placeholder="Allowed redirect URLs (one per line)"
            required
            :disabled="saving"
            v-model="redirectUrls"
          ></textarea>
        </div>
        <div class="form-group">
          <textarea
            type="text"
            class="form-control"
            rows="3"
            placeholder="Allowed widget origins (one per line)"
            :disabled="saving"
            v-model="consentOrigins"
          ></textarea>
        </div>
        <div class="form-group">
          <textarea
            type="text"
            class="form-control"
            rows="3"
            placeholder="Allowed webhook origins (one per line)"
            :disabled="saving"
            v-model="webhookOrigins"
          ></textarea>
        </div>
        <div class="form-group">
          <textarea
            type="text"
            class="form-control"
            rows="2"
            :required="!isOptional"
            :placeholder="`Application description ${isOptional ? '(optional)' : ''}`"
            :disabled="saving"
            v-model="description"
          ></textarea>
        </div>
        <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Email for data protection matters"
              required
              v-model="gdprEmail"
            />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            :required="!isOptional"
            :placeholder="`Privacy URL of the application ${isOptional ? '(optional)' : ''}`"
            :disabled="saving"
            v-model="privacyUrl"
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            :required="!isOptional"
            :placeholder="`Terms URL of the application ${isOptional ? '(optional)' : ''}`"
            :disabled="saving"
            v-model="termsUrl"
          />
        </div>
      </div>
    </div>
    <div>
      <button class="btn btn-primary" type="submit" :disabled="saving">
        <span
          v-if="saving"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Save
      </button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { envs } from '../../constants.js'

export default {
  name: 'ApplicationsItemEdit',
  data() {
    return {
      envs,
      saving: false,
      description: '',
      name: '',
      gdprEmail: '',
      privacyUrl: '',
      redirectUrls: '',
      consentOrigins: '',
      webhookOrigins: '',
      termsUrl: ''
    }
  },
  components: {
  },
  props: {
    app: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['user', 'idToken', 'loading']),
    isOptional() {
      return this.app.environment === envs.Sandbox || this.app.broker_origin
    }
  },
  mounted() {
    this.setFormData(this.app)
  },
  methods: {
    ...mapActions(['editApplication', 'updateApplication']),
    setFormData(app) {
      this.description = app.description || ''
      this.name = app.name || ''
      this.privacyUrl = app.privacy_url || ''
      this.redirectUrls = app.redirect_urls.join('\n')
      this.consentOrigins = app.consent_origins ? app.consent_origins.join('\n') : ''
      this.webhookOrigins = app.webhook_origins ? app.webhook_origins.join('\n') : ''
      this.termsUrl = app.terms_url || ''
      this.gdprEmail = app.gdpr_email || ''
    },
    async editApp() {
      const payload = {
        appId: this.app.kid,
        description: this.description.trim(),
        name: this.name.trim(),
        privacy_url: this.privacyUrl.trim(),
        redirect_urls: this.redirectUrls
          .split('\n')
          .map((u) => u.trim())
          .filter((u) => !!u),
        consent_origins: this.consentOrigins
          .split('\n')
          .map((u) => u.trim())
          .filter((u) => !!u),
        webhook_origins: this.webhookOrigins
          .split('\n')
          .map((u) => u.trim())
          .filter((u) => !!u),
        terms_url: this.termsUrl.trim(),
        gdpr_email: this.gdprEmail.trim()
      }
      this.saving = true
      try {
        const r = await this.editApplication(payload)
        if (r) {
          this.setFormData(await this.updateApplication(this.app.kid))
          this.$emit('success', this.app.kid)
        }
      } finally {
        this.saving = false
      }
    }
  }
}
</script>
