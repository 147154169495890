<template>
  <div>
    <Navbar />
    <main>
      <section class="container py-5">
        <div id="firebaseui-auth-container"></div>
        <div class="row justify-content-md-center mt-4">
          <div class="col-md-4 text-center" v-if="user">
            You are already signed in as <strong>{{ user.email }}</strong>
          </div>
          <div class="col-md-4" v-else>
            <strong>Don't have an account yet?</strong>
            Enter your email, and your account will be automatically created when you follow one-time authentication link.
          </div>
        </div>
      </section>

      <AsideFooter />
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import firebase from 'firebase/app'
import * as firebaseui from 'firebaseui'
import { firebaseAuth } from '../firebase.js'
import AsideFooter from '../components/AsideFooter.vue'
import Navbar from '../components/Navbar.vue'
import metadata from '../mixins/metadata.js'

export default {
  name: 'sign-in',
  data() {
    return {
      ui: null
    }
  },
  mixins: [metadata],
  components: {
    AsideFooter,
    Navbar
  },
  beforeDestroy() {
    this.ui.delete()
  },
  computed: {
    ...mapGetters(['user'])
  },
  mounted() {
    this.ui = new firebaseui.auth.AuthUI(firebaseAuth)
    this.ui.start('#firebaseui-auth-container', {
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
        }
      ],
      signInSuccessUrl: new URLSearchParams(window.location.search).get('next') || '/cp'
    })
    console.log(this.user) // eslint-disable-next-line
  }
}
</script>
