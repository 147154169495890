<template>
    <div>
      <Navbar />
      <main>
        <section>
          <div class="container">
            <div class="text-center mt-5">
              <strong>Sorry, MFA unenrollment is not supported, if you have any questions, please contact us at support.api@enablebanking.com</strong>
            </div>
          </div>
        </section>
      </main>
      <AsideFooter />
    </div>
  </template>
  
  <script>
  import AsideFooter from '../components/AsideFooter.vue'
  import Navbar from '../components/Navbar.vue'
  import metadata from '../mixins/metadata.js'
  
  export default {
    name: 'MFAUnenrollment',
    mixins: [metadata],
    components: {
      AsideFooter,
      Navbar
    }
  }
  </script>
