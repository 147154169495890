<template>
  <div>
    <div v-if="!linking">
      <button class="btn btn-primary" :disabled="loadingASPSPs" @click="linkAccounts()">
        <span
          v-if="loadingASPSPs"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Link accounts
      </button>
    </div>
    <div v-else>
      <div class="form-row align-items-center">
        <div class="col">
          <select
            class="form-control"
            required
            :disabled="linkingStated"
            v-model="country"
            v-on:change="aspsp = ''; usage = ''"
          >
            <option value disabled selected>Country</option>
            <option
              v-for="country in Object.keys(countries).sort((a, b) => countriesList[a].localeCompare(countriesList[b]))"
              :value="country"
              :key="`${appKid}-country-${country}`"
            >{{ countriesList[country] }}</option>
          </select>
        </div>
        <div class="col">
          <select
            class="form-control"
            required
            :disabled="!country || linkingStated"
            v-model="aspsp"
            v-on:change="usage = ''"
          >
            <option value disabled selected>ASPSP</option>
            <option
              v-for="aspsp in countries[country]"
              :value="aspsp"
              :key="`${appKid}-aspsp-${country}-${aspsp.name.replace(/\s+/g, '-')}`"
            >{{ aspsp.name }}</option>
          </select>
        </div>
        <div class="col">
          <select
            class="form-control"
            required
            :disabled="!aspsp || linkingStated"
            v-model="usage"
          >
            <option value disabled selected>Usage type</option>
            <option
              v-for="usage in aspsp ? aspsp.psu_types : []"
              :value="usage"
              :key="`${appKid}-aspsp-${country}-${aspsp.name.replace(/\s+/g, '-')}-${usage}`"
            >{{ usage }}</option>
          </select>
        </div>
        <div class="col">
          <button
            class="btn btn-primary"
            :disabled="!usage || linkingStated"
            @click="startAccountsLinking()"
          >
            <span
              v-if="linkingStated"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Link
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'AccountsLinking',
  data() {
    return {
      loadingASPSPs: false, // when fetching ASPSPs list
      linking: false, // when fetched ASPSPs list and ready to link
      linkingStated: false, // when linking started
      countries: null,
      country: '',
      aspsp: '',
      usage: ''
    }
  },
  props: {
    appKid: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['user', 'idToken']),
    ...mapState({
      countriesList: state => state.countriesList
    })
  },
  methods: {
    ...mapActions(['pushAlertMessage']),
    async linkAccounts() {
      this.loadingASPSPs = true
      try {
        const aspspsResponse = await axios.get(
          '/api/aspsps',
          { headers: { Authorization: `Bearer ${this.idToken.token}` } }
        )
        const countries = {}
        for (const aspsp of aspspsResponse.data.aspsps) {
          const countryASPSPs = countries[aspsp.country] || []
          aspsp.psu_types = aspsp.psu_types.sort()
          countryASPSPs.push(aspsp)
          countries[aspsp.country] = countryASPSPs.sort((a, b) => a.name.localeCompare(b.name))
        }
        this.countries = countries
        this.linking = true
      } catch (e) {
        this.pushAlertMessage({ message: 'Unable to get ASPSPs' })
      } finally {
        this.loadingASPSPs = false
      }
    },
    async startAccountsLinking() {
      this.linkingStated = true
      const query = new URLSearchParams()
      query.set('country', this.country)
      query.set('aspsp', this.aspsp.name)
      query.set('appId', this.appKid)
      query.set('psuType', this.usage)
      query.set('redirectUrl', `${window.location.origin}/api/auth_redirect`)
      try {
        const linkAccountsResponse = await axios.post(
          '/api/link_accounts',
          query.toString(),
          { headers: { Authorization: `Bearer ${this.idToken.token}` } }
        )
        window.location.href = linkAccountsResponse.data.url
      } catch (e) {
        this.pushAlertMessage({ message: 'Unable to start accounts linking' })
        this.linkingStated = false
      }
    }
  }
}
</script>
